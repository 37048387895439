<template>
<div>
  <MainHeader></MainHeader>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <b-button @click="connectTeamSnap">Connect TeamSnap</b-button>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>

import TeamSnap from '@/mixins/TeamSnap'

export default {
  name: 'TeamList',
  mixins: [TeamSnap],
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
    connectTeamSnap () {
      this.loginTeamSnap()
    }
  }
}
</script>
